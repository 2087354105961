// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Scroll from 'react-scroll';

// Components
import Hero from './Hero';
import LogoList from './LogoList';
import Meta from '../shared/Meta';
import ServicesForm from './ServicesForm';
import Triple from './Triple';
import CtaImage from './CtaImage';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';
import servicesActions from '../../actions/servicesActions';

// Selectors
import { getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import { getUrl } from '../../modules/helpers';
import styles from '../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from './Triple.module.postcss';

class Breweries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      submitting: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  onFormSubmit(data) {
    this.setState({ submitting: true });

    this.props.onServicesFormSubmit(data).then(() => {
      this.setState({
        submitted: true,
        submitting: false,
      });
    });

    return false;
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div>
        <Meta
          title={`Food ${cityConfig.vehicle_type}s for Taprooms & Breweries - ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Schedule a daily or weekly rotation of food ${vehicleType}s to visit your brewery or taproom all at no cost to your business!`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Breweries",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />
        <Hero photo="https://static.seattlefoodtruck.com/photos/Breweries.jpeg">
          <h1>Food {cityConfig.vehicle_type}s &amp; Beer</h1>
          <p>Schedule a daily or weekly rotation of food {vehicleType}s to visit your brewery or taproom and sell food directly to your hungry customers. All at no cost to your business!</p>
          <Scroll.Link
            smooth
            to="form"
            className="Button"
          >
            Start the conversation
          </Scroll.Link>
        </Hero>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <h4>How it works</h4>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about1.jpeg"
                title="Curated Schedule"
                text={`Our proprietary platform curates and schedules ${cityConfig.city_name}'s best food ${vehicleType}s to visit your brewery or taproom on a daily rotation. By learning your customer's favorite food ${vehicleType}s, your schedule changes as it goes, with cuisines from all over the world.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about2.jpg"
                title="Fully Automated"
                text={`Your customers can receive weekly menu emails of food ${vehicleType}s scheduled each week. A customized web page is also built for your location to view that week's food ${vehicleType} lunch lineup. Leave the food program to the experts!`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about3.jpg"
                title="Hassle Free"
                text={`Food ${vehicleType}s arrive, serve and sell lunch or dinner to your customers and leave with no mess behind. Customers can order at the truck or skip the line and order online. All food is made to order from a gourmet kitchen on wheels.`}
              />
            </div>
          </div>
        </section>

        <section className="PaddedSection">
          <div className="Container">
            <LogoList breweries />
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <h4>Serve food at your brewery without a kitchen.</h4>
            <div className={tripleStyles.Triple_wrapper + " " + tripleStyles.Triple_wrapper__two + " " + tripleStyles.Triple_wrapper__services}>
              <Triple
                image="https://static.seattlefoodtruck.com/photos/Attract_more_customers.jpeg"
                title="Attract more customers"
                text="Breweries that have on-site food see up to a 30% increase in beverage sales. Help build a sense of joint activity and community while giving your customers new dining options with their delicious beverages!"
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/Burden_Off_Staff.jpeg"
                title="Remove the burden on your staff"
                text={`Juggling food ${vehicleType} schedules can be a full-time job for your taproom manager, with schedule changes, vendor no-shows, and keeping constant variety for your guests. Turn your food program on auto-pilot, with a rotating selection of delicious cuisines from the best food ${vehicleType}s.`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/Focus_on_your_brewery.jpeg"
                title="Focus on your brewery, not a full kitchen"
                text={`Kitchen build-outs can cost hundreds of thousands of dollars. and require constant staff. Food ${vehicleType}s can provide on-site options while maintaining safe distancing protocols, helping customers stay longer (and drink more).`}
              />
              <Triple
                image="https://static.seattlefoodtruck.com/photos/about7.png"
                title="Support local businesses"
                text={`Our owner-operated food ${vehicleType}s favor quality, variety and sustainability. Experience cuisines from all over the world right at your brewery.`}
              />
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/bread.jpeg" imgClass="img-bread"/>
              <div className={styles.Cta_copy}>
                <h4>Customer Credit Packages, Incentives and Rewards</h4>
                <p>Offer free meals, credits and a rewards program to incentivize current and prospective customers and give back to loyal customers.</p>
                <div>
                  <Scroll.Link
                    smooth
                    to="form"
                    className="Button"
                  >
                    Get Started!
                  </Scroll.Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Scroll.Element name="form">
          <section className="PaddedSection--morePadding">
            <div className="Container">
              <h4>Let us help with your dining program needs</h4>
              {this.state.submitting && (
                <p>Sending your inquiry...</p>
              )}
              {this.state.submitted && (
                <p>Your inquiry has been sent. We'll be in touch soon!</p>
              )}
              {!this.state.submitting && !this.state.submitted && (
                <ServicesForm onSubmit={this.onFormSubmit} formType="breweries" />
              )}
            </div>
          </section>
        </Scroll.Element>
      </div>
    );
  }
}

Breweries.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  onServicesFormSubmit: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    },
    onServicesFormSubmit(data) {
      return dispatch(servicesActions.onServicesFormSubmit(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Breweries);
